import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import {API } from 'aws-amplify';
import { Table, Button, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Form from "@rjsf/core";

const schema = {
  type: "object",
  required: ["title", "date", "url_link", "is_shown"],
  properties: {
    "title": {
      title: "Event title:",
      type: "string"
    },
    "date": {
      title: "Date:",
      type: "string",
      format: "date"
    },
    "url_link": {
      title: "Web URL:",
      type: "string",
      format: "uri"
    },
    "is_shown": {
      type: "boolean"
    }
  }
};

const uiSchema = {
  "is_shown": {
    "ui:widget": "select" // could also be "select"
  },
  "url_link": {
    "ui:placeholder": "https://"
  }
};

export default function WhatsOnEditor(props) {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);

  const loadAllEvents = React.useCallback(async () => {
    try {
      //console.log(await Auth.currentSession());
      let evs = await API.get('WelcomeAPI', '/admin/events');
      setData(evs);
      console.log(evs);
      return (evs);
    } catch (err) {
      alert("Unable to fetch events; make sure you are connected to internet.");
      console.log(err);
    }
  }, []);

  useEffect(() => {
    loadAllEvents();
    return (() => {
      //console.log('unmounted');
    });
  }, [loadAllEvents]);

  const handleEdit = React.useCallback((row) => {
    console.log({ ...row, date: row.date.split("T")[0] });
    setFormData({ ...row, date: row.date.split("T")[0] });

    setShowModal(true);

  }, []);

  const handleNewEvent = React.useCallback(() => {
    setFormData({});
    setShowModal(true);
  }, []);

  const handleDelete = React.useCallback(async (id) => {
    console.log(id);
    if (window.confirm('Are you sure you want to remove this event?')) {
      try {
        setIsSubmitting(true);
        const init = {
          body: { id }
        };
        const res = await API.del('WelcomeAPI', '/admin/events', init);
        setShowModal(false);
        console.log(res);
        loadAllEvents();
      } catch (err) {
        console.log(err);
        alert('An error occured sending data to the server');
      } finally {
        setIsSubmitting(false);
      }
    }
  }, [loadAllEvents]);


  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id', // accessor is the "key" in the data
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => ((new Date(value)).toLocaleDateString('en-AU'))
      },
      {
        Header: 'Event/News feed',
        accessor: 'title',
      },
      {
        Header: 'URL',
        accessor: 'url_link',
        Cell: ({ value }) => (<a href={value} target='_blank' rel='noopener noreferrer' title={value}>Go</a>)
      },
      {
        Header: 'Shown?',
        accessor: 'is_shown',
        Cell: ({ value }) => (value ? 'Yes' : 'No')
      },
      {
        Header: 'action',
        Cell: (args) => { return (<Button color="warning" onClick={() => handleEdit(args.row.original)}>Edit</Button>); }
      }
    ],
    [handleEdit]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const handleSubmit = React.useCallback(async (e) => {
    console.log('submitted', e.formData);
    try {

      setIsSubmitting(true);
      const init = {
        body: {
          ...(e.formData)
        }
      };
      const res = !(e.formData.id) ? await API.put('WelcomeAPI', '/admin/events', init) : await await API.patch('WelcomeAPI', '/admin/events', init);
      setShowModal(false);
      console.log(res);
      loadAllEvents();

    } catch (err) {
      console.log(err);
      alert('An error occured sending data to the server');
    } finally {
      setIsSubmitting(false);
    }

  }, [loadAllEvents]);



  return (
    <>
      <Row style={{ marginTop: '10px' }}>
        <Col>
          <Table {...getTableProps()} striped>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps()} >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()} >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button color="success" onClick={handleNewEvent}>+New event</Button>
        </Col>
        <Col /><Col /><Col />
      </Row>
      <Modal isOpen={showModal} >
        <ModalHeader >Event or news feed details</ModalHeader>
        <ModalBody>
          <Form schema={schema}
            formData={formData} uiSchema={uiSchema} onChange={(e) => setFormData(e.formData)} onSubmit={handleSubmit} noHtml5Validate  >
            <Button type="submit" color="primary" disabled={isSubmitting}>Save</Button>{' '}
            {(formData && formData.id) && <Button color="danger" disabled={isSubmitting} onClick={() => handleDelete(formData.id)}>Delete event</Button>}
            {' '}<Button color="secondary" onClick={() => setShowModal(false)}> Cancel</Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )


}