import React from 'react';
import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import WhatsOnEditor from './components/whatsoneditor';
import {Container,Row,Col} from 'reactstrap';


function App() {
  return (
    <Container>
      <Row>
        <Col/>
        <Col/>
        <Col/>
        <Col>
         <AmplifySignOut />
         </Col>
        </Row>
        <WhatsOnEditor />
    </Container>
  );
}

const ProtectedApp=withAuthenticator(App);
export default ProtectedApp;
