import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';


Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: "ap-southeast-2:fe78448d-b51d-4194-9ddd-7650707ef5e3",
    // REQUIRED - Amazon Cognito Region
    mandatorySignIn: false,
    region: "ap-southeast-2",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_MePXlYTJX",
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "6rorsjbjrcs7509q1mtsfb7pjt",
    
  },
	API: {
		endpoints: [
			{
				name: "WelcomeAPI",
				endpoint: "https://aai8fzc0t5.execute-api.ap-southeast-2.amazonaws.com/dev",
				region: "ap-southeast-2"
			}
    ]
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
